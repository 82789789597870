import React from "react";
import { graphql } from "gatsby";

import componentsMap from "components/components";
import Layout from "components/common/Layout";
import useStoryblok from "utils/hooks/useStoryblok";

function Post({ pageContext, location, data }) {
  const { lang, languages, story } = pageContext;
  const { layout, featuredNews } = data;
  const pageStory = useStoryblok(story, location);
  const { content } = pageStory;
  const { uuid, metadata, component } = content;
  const parsedLayout = layout ? JSON.parse(layout.content) : {};
  const { header, footer } = parsedLayout;

  if (story == null) {
    return (
      <div>
        <h3>Editor page</h3>
      </div>
    );
  }

  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      seo={metadata}
      pathname={location.pathname}
      languages={languages}
      lang={lang}
      siteName="News"
    >
      {React.createElement(componentsMap[component], {
        key: uuid,
        blok: content,
        lang: lang,
        path: location.href,
        featuredNews,
      })}
    </Layout>
  );
}
export default Post;

export const postQuery = graphql`
  query PostQuery($uuid: String!) {
    layout: storyblokEntry(field_component: { eq: "common_layout" }) {
      content
    }
    featuredNews: allStoryblokEntry(
      filter: { uuid: { ne: $uuid }, field_component: { eq: "common_post" } }
      sort: { order: DESC, fields: [field_post_date_string] }
      limit: 3
    ) {
      edges {
        node {
          content
          slug
        }
      }
    }
  }
`;
